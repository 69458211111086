import React from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { Wrapper, ModalWindow, Img, Icon } from "./styles";

// Images
import cross from "./img/cross.png";

const Modal = ({ setIsModalShown }) => {
  const url = "https://ethereum-express.com";
  const shareText = "World’s first community-driven ecosystem on blockchain";
  return (
    <Wrapper onClick={() => setIsModalShown(false)}>
      <ModalWindow>
        <Img src={cross} onClick={() => setIsModalShown(false)} />

        <FacebookShareButton quote={shareText} url={url}>
          <Icon>
            <FacebookIcon round />
          </Icon>
        </FacebookShareButton>

        <TwitterShareButton title={shareText} url={url}>
          <Icon>
            <TwitterIcon round />
          </Icon>
        </TwitterShareButton>
      </ModalWindow>
    </Wrapper>
  );
};

export default Modal;
