// Absolute
import React from "react";

// Styles
import { Root, Logo, LogoImg, LogoDescription } from "./styles";

// Img
import siteLogoImg from "./site-logo.svg";

const Background = () => {
  return (
    <Root>
      <Logo>
        <LogoImg src={siteLogoImg} alt="logo" />
        <LogoDescription>
          Ethereum <br />
          Express <br />
          Coin
        </LogoDescription>
      </Logo>
    </Root>
  );
};

export default Background;
