import styled from "styled-components";

import { breakpoints } from "../../theme/breakpoints";

export const Wrapper = styled.section`
  position: fixed;
  z-index: 1;
  overflow: auto;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background: rgba(18, 7, 44, 0.9);
`;

export const ModalWindow = styled.div`
  width: 30%;
  height: 20vh;
  background: #fff;
  border-radius: 4px;
  color: #2c2a32;
  font-family: "Fira Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: ${breakpoints.sm}px) {
    width: 80%;
  }
`;

export const Icon = styled.div`
  margin: 0 10px;
`;

export const Img = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
