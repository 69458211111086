// Absolute
import React from "react";

//Components
import Background from "../Background";

// Styles
import { Info, H1, Text } from "./styles";

const Header = () => {
  return (
    <header>
      <Background />
      <Info>
        <H1>Connect with us on social media</H1>
        <Text>
          Follow us and get updates delivered to your favorite social media
          channel.
        </Text>
      </Info>
    </header>
  );
};

export default Header;
