import React, { useState } from "react";
import GlobalStyle from "./theme/global-style";

// Components
import Header from "./components/Header";
import Link from "./components/Link";
import Shared from "./components/Shared";
import Modal from "./components/Modal";

//Styles
import { Container, Wrapper } from "./styles";

const data = [
  {
    title: "Visit us online",
    text: "ethereum-express.com",
    link: "https://ethereum-express.com/",
    icon: "EEXIcon"
  },
  {
    title: "Facebook",
    text: "Become a fan",
    link: "https://www.facebook.com/ethereumexpressfoundation",
    icon: "FacebookIcon"
  },
  {
    title: "Youtube",
    text: "Watch our videos",
    link: "https://www.youtube.com/channel/UC8nJLBfVD4lrs0_Zc4AYLEg",
    icon: "YoutubeIcon"
  },
  {
    title: "Register on our Exchange!",
    text: "cex.ethereum-express.com",
    link: "https://cex.ethereum-express.com/",
    icon: "CEXIcon"
  },
  {
    title: "Join Telegram!",
    text: "t.me",
    link: "https://t.me/EthereumExpress",
    icon: "TelegramIcon"
  },
  {
    title: "Twitter",
    text: "Follow Us",
    link: "https://twitter.com/ethereumexpress",
    icon: "TwitterIcon"
  }
];

function App() {
  const [isModalShown, setIsModalShown] = useState(false);
  return (
    <>
      <GlobalStyle />
      <Header />

      <Container>
        {data.map(item => (
          <Link
            key={item.title}
            link={item.link}
            title={item.title}
            text={item.text}
            icon={item.icon}
          />
        ))}
      </Container>
      <Wrapper>
        <Shared setIsModalShown={setIsModalShown} />
      </Wrapper>

      {isModalShown && <Modal setIsModalShown={setIsModalShown} />}
    </>
  );
}

export default App;
