import styled from "styled-components";

import { breakpoints } from "../../theme/breakpoints";

export const Button = styled.div`
  width: 60px;
  height: 60px;

  background: rgba(68, 30, 218, 0.8);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(68, 30, 218, 1);
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 50px;
    height: 50px;
    background: rgba(68, 30, 218, 1);
  }
`;
