import styled from "styled-components";

export const Container = styled.section`
  padding: 0 4%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 10px;
`;
