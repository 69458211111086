import React from "react";

// Components
import Icon from "../Icon/Icon";

// Styles
import { Root, H1, Text, Flex } from "./styles";

const Link = ({ link, title, text, icon }) => {
  return (
    <Root href={link} target="_blank">
      <Icon icon={icon} />
      <Flex>
        <H1>{title}</H1>
        <Text>{text}</Text>
      </Flex>
    </Root>
  );
};

export default Link;
