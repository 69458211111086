import styled from "styled-components";

import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.div`
  background: linear-gradient(to left, #261066, #3a1269, #471368, #59176c);
  height: 14vh;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.sm}px) {
    height: 12vh;
  }
`;

export const Logo = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const LogoImg = styled.img`
  width: 54px;
  height: 54px;
  object-fit: contain;

  @media (max-width: ${breakpoints.sm}px) {
    width: 44px;
    height: 44px;
  }
`;

export const LogoDescription = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: -0.47px;
  margin-left: 12px;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;
