import styled from "styled-components";

import { breakpoints } from "../../theme/breakpoints";

export const Img = styled.img``;

export const Info = styled.section`
  background: linear-gradient(to right, rgba(244, 244, 244, 0.7), #ccc);
  min-height: 16vh;
  color: #441eda;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
`;

export const H1 = styled.h1`
  font-size: 26px;
  margin-bottom: 10px;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 24px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 20px;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  text-align: center;
  margin-top: 0;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 18px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 16px;
  }
`;
