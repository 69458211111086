import styled from "styled-components";

import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.a`
  text-decoration: none;
  color: #2c2a32;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-bottom: 1px solid #898693;
  &:nth-child(6) {
    border-bottom: none;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

export const H1 = styled.h1`
  font-size: 26px;
  margin-bottom: 0;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 24px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 18px;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: #a8a5b4;
  margin-top: 5px;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 18px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;
