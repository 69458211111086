/**
 * Absolute imports
 */
import { createGlobalStyle } from "styled-components";
import "typeface-roboto";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, 
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  html {
    font-weight: 400;
    font-style: normal;
    font-family: 'Fira Sans', 'Roboto','Helvetica','Arial',sans-serif;
    color: black;
    font-size: 16px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default GlobalStyle;
